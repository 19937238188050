import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SEO from '../../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title='Bookshelves' mdxType="SEO" />
    <h2>{`Bookshelves`}</h2>
    <p>{`My wife and I wanted to have some bookshelves that fit next to our fireplace and in other parts of our house. I wanted something minimal and sturdy, so I started to work out some designs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      